
import { defineComponent } from "vue";
import axios from "axios";
import moment, { Moment } from "moment";

import AppPage from "../components/AppPage.vue";
import Preloader from "../components/Preloader.vue";
import DataTable from "../components/DataTable.vue";
import TextInput from "../components/TextInput.vue";
import DateRangeInput from "../components/DateRangeInput.vue";
import SubsetInput from "../components/SubsetInput.vue";
import Editor from "../views/Campaigns/Editor.vue";

import { Sorting, Pagination, Option } from "../modules/types";

interface IDataModel {
  loading: boolean;
  query: IQuery;
  rows: any[];
  domains: any[];
  domains_parked: Option[];
  totalRows: number;
  previousQuery: IQuery | null;
  columns: any[];
  categories: Option[];
  campaignToAdd: any;
  geos: any;
}

interface IQuery {
  period: Moment[];
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  category_ids: number[];
  geo_ids: number[];
}

export function getStoredQuery(previous = false): IQuery | null {
  const query = localStorage.getItem(
    previous ? `news.previous_query` : `news.query`
  );
  if (!query) {
    return null;
  }
  const parsed: IQuery = JSON.parse(query);
  parsed.period = parsed.period.map((x: any) => moment(x));
  return parsed;
}

export function getDefaultQuery(): IQuery {
  return {
    period: [moment().endOf("day").subtract(29, "days"), moment().endOf("day")],
    search: "",
    sorting: { column: "created_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
    category_ids: [],
    geo_ids: [],
  };
}

const dataColumns = [
  {
    id: "created_at",
    name: "Дата новости",
    selected: true,
    sortable: true,
  },
  {
    id: "image_filename",
    name: "Картинка",
    selected: true,
    sortable: false,
    htmlClass: "news__table--image",
  },
  {
    id: "article_name",
    name: "Заголовок статьи",
    selected: true,
    sortable: true,
    htmlClass: "news__table--h",
    type: "text",
  },
  {
    id: "category_name",
    name: "Маркер категории",
    selected: true,
    sortable: true,
    htmlClass: "news__table--mark",
    type: "text",
  },
  {
    id: "geo",
    name: "Гео",
    selected: true,
    sortable: false,
    htmlClass: "news__table--lang",
  },
  {
    id: "buttons",
    name: "",
    selected: true,
    freezed: true,
    sortable: false,
    htmlClass: "news__table--action",
  },
];

export default defineComponent({
  components: {
    AppPage,
    Preloader,
    TextInput,
    DataTable,
    DateRangeInput,
    SubsetInput,
    Editor,
  },

  data(): IDataModel {
    return {
      loading: false,
      query: getDefaultQuery(),
      rows: [],
      domains: [],
      domains_parked: [],
      totalRows: 0,
      previousQuery: getStoredQuery(true),
      columns: dataColumns,
      categories: [],
      campaignToAdd: null,
      geos: [],
    };
  },

  created() {
    this.load();
  },

  mounted() {
    this.$watch("query.sorting", this.load);
    this.$watch("query.pagination", this.load, { deep: true });
  },

  methods: {
    getGeoIdByIsoCode(iso_code: string): number {
      return this.geos.find((x: any) => x.iso_code === iso_code)?.id;
    },
    apply() {
      const previousQueryJson = localStorage.getItem("news.query");
      if (previousQueryJson) {
        localStorage.setItem("news.previous_query", previousQueryJson);
        this.previousQuery = getStoredQuery(true);
      }

      const json = JSON.stringify(this.query);
      localStorage.setItem("news.query", json);

      this.load();
    },

    applyPrevious() {
      const stored = getStoredQuery(true);
      if (stored) {
        this.query = stored;
      }
    },

    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },

    clear() {
      this.query = getDefaultQuery();
    },

    openNews(id: number, iso_code: string) {
      const geo_id = this.getGeoIdByIsoCode(iso_code);
      const domain = this.domains.find((x: any) => x.geo_id === geo_id)?.name;
      window.open(`https://${domain}/${id}`, "_blank");
    },

    async getCategories(query = "") {
      const { data } = await axios.get("/api/categories/lookup", {
        params: { q: JSON.stringify(query) },
      });
      this.categories = data;
    },

    async getGeos(query = "") {
      const { data } = await axios.get("/api/geos/lookup-for-news", {
        params: { q: JSON.stringify(query) },
      });
      this.geos = data;
    },

    updateCategoriesAndLoad(e: any) {
      this.query.category_ids = e;
      if (this.query.pagination) {
        this.query.pagination.offset = 0;
      }
    },

    updateGeoAndLoad(e: any) {
      this.query.geo_ids = e;
      if (this.query.pagination) {
        this.query.pagination.offset = 0;
      }
    },

    async load() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.rows = [];
      this.totalRows = 0;

      try {
        const [{ data: news }, { data: domains }, { data: domains_parked }] =
          await Promise.all([
            axios.get("/api/news", {
              params: { q: JSON.stringify(this.query) },
            }),
            axios.get("/api/domains"),
            axios.get("/api/domains-parked/lookup", {
              params: { q: JSON.stringify({ query: "", is_parked: true }) },
            }),
          ]);
        this.domains = domains;
        this.domains_parked = domains_parked;
        this.rows = news.rows;
        this.totalRows = news.total;
        this.getCategories();
        this.getGeos();

        if (!this.rows.length) {
          this.query.pagination!.offset = 0;
          this.load();
        }
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },

    openEditor(row: any, geo_id: number) {
      this.campaignToAdd = {
        name: "",
        page_type_id: row.type_id,
        design_id: 1,
        geo_id,
        domain_id: null,
        domain_parked_id: null,
        news_id: row.id,
        has_extra_monetization: false,
        has_popup: false,
        has_push: false,
        has_postback: false,
        sid1: "",
        sid2: "",
        sid3: "",
        sid4: "",
        postback_url: "",
      };
    },
  },
});
